import React, { useState, useRef } from 'react'
import {
    IconButton,
    MenuList,
    MenuItem,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
} from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert'

const classes = {
    root: {
        display: 'flex',
    },
    paper: {
        width: '131px',
    },
    menuItem: {
        width: '131px',
    },
}

export default function IntegrationCornerMenu({ items }) {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-label="more options"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleToggle}
                size="medium"
            >
                <MoreVertIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="left-start"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper sx={classes.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {items.map((item, index) => {
                                        return (
                                            item && (
                                                <MenuItem
                                                    key={index}
                                                    value={item.text}
                                                    {...item}
                                                    sx={classes.menuItem}
                                                >
                                                    {' '}
                                                    {item.text}{' '}
                                                </MenuItem>
                                            )
                                        )
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
