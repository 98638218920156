// TODO: can we consolidate this with other filter bars, specifically PartnerFilterToolbar?
// We should revisit this when userinfo is ready for the paging treatment, because that page
// will also need a similar filter bar

import React, { useState } from 'react'
import {
    Grid,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material'
import IntegrationSelectFilter from '../../List/Filters/IntegrationSelectFilter'
import { useListFilterContext } from 'react-admin'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

const tenantFilterToolbarStyles = {
    archiveField: {
        height: '38px',
        borderRadius: 0,
        width: 'auto',
        '& > div': {
            fontSize: '13px',
            fontWeight: 'bold',
        },
        '&:hover': {
            '& > fieldset': {
                border: '2px solid black',
            },
        },
        minWidth: '150px',
        '& div:focus': { backgroundColor: 'white' },
    },
    gridContainer: {
        margin: '10px 30px 10px 0',
    },
    leftGrid: {
        pt: '1px',
        '& > form': {
            display: 'flex',
            height: '35px',
        },
    },
    gridItem: {
        padding: '0px 12px 0px 12px',
    },
    filterRoot: {
        display: 'flex',
    },
    textField: {
        '& > div.MuiInputBase-root': {
            width: '297px',
            height: '38px',
            borderRadius: '0',
            fontSize: 'auto',
            '&:hover': {
                '& > fieldset': {
                    border: '2px solid black',
                },
            },
        },
        '& > div.MuiInputBase-adornedStart': {
            paddingLeft: '0px',
        },
    },
    clearButton: {
        fontFamily: 'RobotoCondensedBold',
        letterSpacing: '1.5px',
        color: '#626FFC',
        mt: '2px',
    },
    searchIcon: {
        color: 'rgba(0,0,0,0.54)',
        padding: '6px 0px 5px 11px',
        fontSize: '24px',
    },
}

/**
 *
 * @param isSuperUser
 * @returns {*}
 * @constructor
 */
export default function TenantFilterToolbar({ isSuperUser }) {
    const { filterValues, setFilters } = useListFilterContext()
    const [customerFilter, setCustomerFilter] = useState(
        filterValues['customer__contains'] || ''
    )
    const handleFilterChange = (source, e) => {
        const newFilters = { ...filterValues, [source]: e.target.value }
        setFilters(newFilters, {}, true)
    }

    const clear = () => {
        setCustomerFilter('')
        setFilters({})
    }

    return (
        <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid item>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon
                                    sx={tenantFilterToolbarStyles.searchIcon}
                                />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    value={customerFilter}
                    label=""
                    sx={tenantFilterToolbarStyles.textField}
                    placeholder={'Search by customer or tenant name'}
                    onChange={(e) => {
                        setCustomerFilter(e.target.value)
                        handleFilterChange('customer__contains', e)
                    }}
                />
            </Grid>
            <Grid item>
                <IntegrationSelectFilter
                    handleChange={handleFilterChange}
                    filters={filterValues}
                    tenantList={true}
                    isSuperUser={isSuperUser}
                />
            </Grid>
            <Grid item>
                <FormControl variant="outlined">
                    <InputLabel margin="dense" variant="outlined" size="small">
                        Tenant Status
                    </InputLabel>
                    <Select
                        label={'Tenant Status'}
                        sx={tenantFilterToolbarStyles.archiveField}
                        value={filterValues['archived'] || 'false'}
                        defaultValue={'false'}
                        onChange={(e) => handleFilterChange('archived', e)}
                    >
                        <MenuItem value={'false'}>Active</MenuItem>
                        <MenuItem value={'true'}>Archived</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <Button
                    onClick={clear}
                    sx={tenantFilterToolbarStyles.clearButton}
                >
                    Clear All
                </Button>
            </Grid>
        </Grid>
    )
}
