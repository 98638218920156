/**
 * Component taken and edited from React Admin. Modified to remove the xsmall overlay
 * version of the sidebar. And some small style changes to match Pandium's admindash.
 * react-admin/packages/ra-ui-materialui/src/layout/Sidebar.js
 */
import React, { useEffect, Children, cloneElement } from 'react'
import { useSidebarState } from 'react-admin'
import { Drawer, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'

const DRAWER_WIDTH = 240
const CLOSED_DRAWER_WIDTH = 55

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        position: 'relative',
        height: 'auto',
        overflowX: 'hidden',
        width: (props) =>
            props.open
                ? theme?.sidebar?.width || DRAWER_WIDTH
                : theme?.sidebar?.closedWidth || CLOSED_DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'transparent',
        marginTop: '0.5em',
        borderRight: 'none',
        [theme.breakpoints.up('md')]: {
            border: 'none',
            marginTop: '1.5em',
        },
    },
}))

const RASidebar = ({ children, closedSize, size, ...rest }) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'))
    // FIXME negating isXSmall and isSmall should be enough, but unfortunately
    // mui media queries use a two pass system and are always false at first
    // see https://github.com/mui-org/material-ui/issues/14336
    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

    const [open, setOpen] = useSidebarState()
    useSelector((state) => state.locale) // force redraw on locale change
    const handleClose = () => setOpen(false)
    const toggleSidebar = () => {
        setOpen(!open)
    }
    const classes = useStyles({ open })

    useEffect(() => {
        if (isDesktop) {
            setOpen(true)
        }
        if (isSmall) {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSmall])

    return (
        <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
                className: classes.drawerPaper,
            }}
            onClose={toggleSidebar}
            {...rest}
        >
            {cloneElement(Children.only(children), { dense: true })}
        </Drawer>
    )
}

export default RASidebar
