import React, { useState } from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDataProvider } from 'react-admin'
import ConnectorInfoDialog from './ConnectorInfoDialog'
import { API_URI } from '../appConfigs'
import { get, isEmpty } from 'lodash-es'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontFamily: 'Roboto',
        textAlign: 'center',
        padding: '12px 10px',
        fontSize: '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
    },
}))

const ConnectorInfoButton = (props) => {
    const dataProvider = useDataProvider()
    const { connector, tenant } = props
    const [openInfoDialog, setOpenInfoDialog] = useState(false)
    const [webhookUrl, setWebhookUrl] = useState('')
    const [webhookSecretKey, setWebhookSecretKey] = useState('')
    const [dialogLoading, setDialogLoading] = useState(false)
    const tenantStatusAuth = get(tenant, 'status.auth')
    let callbackUri = `${API_URI.replace('v1', 'v0')}/author/callback/oauth2`
    if (connector.name === 'visma') {
        callbackUri =
            callbackUri.replace('oauth2', 'visma_e_conomic') +
            `/${tenant.namespace}/${tenant.integration.name}/${tenant.id}`
    }

    const handleOpenInfoDialog = () => {
        // Only generate the webhook url if webhook_url exists in connector_info and hasnt been generated yet
        if (
            isEmpty(
                get(connector, 'connectorInfo.schema.properties.webhook_url')
            ) ||
            !isEmpty(webhookUrl)
        ) {
            setOpenInfoDialog(true)
            return
        }
        setDialogLoading(true)
        const webhookUrlGenerationData = {
            connector_name: connector.name,
            integration_id: tenant.integration.id,
            tenant_id: tenant.id,
            encode_type: 'base64',
            use_webhook_secret: false,
            skip_encode: get(
                connector,
                'metadata.skip_webhook_url_encode',
                false
            ),
        }
        // Only generate the webhook secret if webhook_signature_key exists in connector_info
        if (
            !isEmpty(
                get(
                    connector,
                    'connectorInfo.schema.properties.webhook_signature_key'
                )
            )
        ) {
            webhookUrlGenerationData['use_webhook_secret'] = true
        }

        dataProvider
            .AUTHOR('author/generate_tenant_connector_webhook_url', {
                data: webhookUrlGenerationData,
            })
            .then((res) => {
                setWebhookUrl(get(res, 'data.webhookUrl', ''))
                setWebhookSecretKey(get(res, 'data.webhookSecretKey', ''))
                setDialogLoading(false)
            })
        setOpenInfoDialog(true)
    }

    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false)
    }

    return (
        <div>
            <StyledTooltip title="Connector information" placement="bottom">
                <span>
                    <Button
                        children="Info"
                        onClick={handleOpenInfoDialog}
                        sx={{
                            color: '#626FFC',
                            fontFamily: 'RobotoCondensedBold',
                            letterSpacing: '1.5px',
                            minWidth: 0,
                            padding: '3px',
                        }}
                    />
                </span>
            </StyledTooltip>

            <ConnectorInfoDialog
                close={handleCloseInfoDialog}
                open={openInfoDialog}
                dialogLoading={dialogLoading}
                webhookUrl={webhookUrl}
                callbackUri={callbackUri}
                webhookSecretKey={webhookSecretKey}
                tenantStatusAuth={tenantStatusAuth}
                connector={connector}
            />
        </div>
    )
}

export default ConnectorInfoButton
